import action_types from '../actionTypes';

const intitialUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('elon_user_data')) : null;

const initial_state = {
    isLoading: false,
    isAuthenticated: false,
    user: intitialUser || null,
    isEmptyForm: false, //TODO change to true
    form: {
        name: '',
        email: '',
        password: ''
    }
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.AUTH_SET_LOADING: {
            return { ...state, isLoading: action.flag }
        }
        case action_types.AUTH_SET_AUTHENTICATED: {
            return { ...state, isAuthenticated: action.flag}
        }
        case action_types.AUTH_SET_USER: {
            return { ...state, user: action.user}
        }
        case action_types.AUTH_INIT: {
            return { ...state, user: action.user, isAuthenticated: action.isAuthenticated}
        }
        case action_types.AUTH_LOGOUT: {
            return { ...state, user: null, isAuthenticated: false}
        }
        case action_types.AUTH_SET_FORM_VALUE: {
            const form = {...state.form};
            form[action.key] = action.value;
            let isEmptyForm = false;

            for (const key in form) {
                if(form[key] == null || form[key].toString().length === 0){
                    isEmptyForm = true;
                }
            }

            return {...state, form, isEmptyForm}
        }
        default:
            return state;
    }
}