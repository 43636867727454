import action_types from '../actionTypes';



const initial_state = {
    isLoading: false,
    amount: null,
    paymentMeta: null,
    secretKey: null,
    fee: null,
    subtotal: null,
    stripeTotal: null,
    total: null,
    method: 'coinbase',
    currentTransaction: {
        meta: {
            link: ''
        }
    }
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.PAYMENT_SET_LOADING: {
            return { ...state, isLoading: action.flag }
        }
        case action_types.PAYMENT_SET_AMOUNT: {
            return { ...state, ...action.data}
        }
        case action_types.PAYMENT_SET_DATA: {
            return { ...state, paymentMeta: action.data.meta, secretKey:action.data.client_secret, checkoutId: action.data.code}
        }
        case action_types.PAYMENT_CLEAR: {
            return {...initial_state}
        }
        case action_types.PAYMENT_SET_TYPE: {
            return { ...state, method: action.method}
        }
        case action_types.PAYMENT_SET_CURRENT_TRANSACTION_DATA: {
            return {...state, currentTransaction: action.data}
        }
        default:
            return state;
    }
}