import { combineReducers } from 'redux';
import auth from './auth';
import info from './info';
import payment from './payment';
import message from './message';

export default combineReducers({
    auth,
    info,
    payment,
    message
});
