export default {
    //AUTH
    AUTH_SET_LOADING: 'AUTH_SET_LOADING',
    AUTH_SET_AUTHENTICATED: 'AUTH_SET_AUTHENTICATED',
    AUTH_SET_USER: 'AUTH_SET_USER',
    AUTH_INIT: 'AUTH_INIT',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_SET_FORM_VALUE: 'AUTH_SET_FORM_VALUE',

    //INFO 
    INFO_SET_DATA: 'INFO_SET_DATA',
    INFO_SET_LOADING: 'INFO_SET_LOADING',
    INFO_SET_PROFILE_LOADING: 'INFO_SET_PROFILE_LOADING',
    INFO_SET_PROFILE_DATA: 'INFO_SET_PROFILE_DATA',
    INFO_SET_REDIRECT_DATA: 'INFO_SET_REDIRECT_DATA',
    INFO_CLEAR_REDIRECT_DATA: 'INFO_CLEAR_REDIRECT_DATA',

    //PAYMENT
    PAYMENT_SET_LOADING: 'PAYMENT_SET_LOADING',
    PAYMENT_SET_AMOUNT: 'PAYMENT_SET_AMOUNT',
    PAYMENT_SET_DATA: 'PAYMENT_SET_DATA',
    PAYMENT_CLEAR: 'PAYMENT_CLEAR',
    PAYMENT_SET_TYPE: 'PAYMENT_SET_TYPE',
    PAYMENT_SET_CURRENT_TRANSACTION_DATA:'PAYMENT_SET_CURRENT_TRANSACTION_DATA',

    //MESSAGE
    MESSAGE_SET_DATA: 'MESSAGE_SET_DATA',
    MESSAGE_CLEAR: 'MESSAGE_CLEAR'

}