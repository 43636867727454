import action_types from '../actionTypes';

const initial_state = {
    message: null
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.MESSAGE_SET_DATA: {
            return { ...state, message: action.data }
        }
        case action_types.MESSAGE_CLEAR: {
            return { ...state, message: null }
        }
        default:
            return state;
    }
}