import action_types from '../actionTypes';

const initial_state = {
    isLoading: false,
    isProfileLoading: false,
    total: null,
    price: 50,
    minQty: 10,
    maxQty: null,
    profileCoins: null,
    redirectData: null
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.INFO_SET_DATA: {
            return { ...state, ...action.data }
        }
        case action_types.INFO_SET_LOADING: {
            return { ...state, isLoading: action.flag }
        }
        case action_types.INFO_SET_PROFILE_LOADING: {
            return { ...state, isProfileLoading: action.flag }
        }
        case action_types.INFO_SET_PROFILE_DATA: {
            return { ...state, profileCoins: action.data.coins, email: action.data.email }
        }
        case action_types.INFO_SET_REDIRECT_DATA: {
            return { ...state, redirectData: action.data }
        }
        case action_types.INFO_CLEAR_REDIRECT_DATA: {
            return { ...state, redirectData: null }
        }
        default:
            return state;
    }
}